import { EventEmitter } from 'events';
import each from 'lodash/each';
import { EnvironmentName } from 'src/environment/environmentName';
import { TokensResponse } from 'src/types/cognito';

import { convertCognitoTokens } from './authentication/convertCognitoTokens';
import { AuthenticationServiceTokens } from './authentication/types';

const STORAGE_KEY = 'secfi.storage';
const tokenKey = 'tokens';

const MapStore = () => {
  const store = new Map<string, any>();
  return {
    clear: store.clear.bind(store),
    getItem: store.get.bind(store),
    key: () => {
      throw Error('not implemented');
    },
    removeItem: store.delete.bind(store),
    setItem: store.set.bind(store),
    get length() {
      return store.size;
    },
  };
};

class SecfiStorage implements Storage {
  emitter = new EventEmitter();

  constructor() {
    if (import.meta.env.VITE_DEPLOYMENT_ENV === EnvironmentName.Production) {
      return;
    }
    this._initialiseApiAndAnalytics();
  }

  private _initialiseApiAndAnalytics() {
    const analyticsKey = 'SECFI_ANALYTICS';
    const apiKey = 'SECFI_API';
    global.SECFI_ANALYTICS = Boolean(this.getItem(analyticsKey));

    if (typeof window !== 'undefined') {
      window.addEventListener('beforeunload', () => {
        if (!this) {
          return;
        }

        if (global.SECFI_ANALYTICS) {
          this.setItem(analyticsKey, String(global.SECFI_ANALYTICS));
        } else {
          this.removeItem(analyticsKey);
        }

        this.removeItem(apiKey);
      });
    }
  }

  private get _store(): Storage {
    let store;

    try {
      store =
        typeof window !== 'undefined' && window.localStorage
          ? window.localStorage
          : MapStore();
    } catch (e) {
      store = MapStore();
    }

    return store;
  }

  public get length(): number {
    return this._keys.length;
  }

  public clear(): void {
    each(this._keys, (key) => {
      this._store.removeItem(key);
    });
  }

  public removeItem(key: string): void {
    key = this.getStorageKeyName(key);
    this._store.removeItem(key);
  }

  public setItem(key: string, value: any): void {
    if (value) {
      const fullKey = this.getStorageKeyName(key);
      value = JSON.stringify(value);
      this._store.setItem(fullKey, value);
      this.emitter.emit(key);
    } else {
      this.removeItem(key);
    }
  }

  public getItem<T = string>(key: string): T | null {
    const storageKey = this.getStorageKeyName(key);
    const value = this._store.getItem(storageKey);

    try {
      if (value === null) {
        return null;
      }

      return JSON.parse(value);
    } catch (err) {
      // if the entry cannot be parsed, consider it false
      this._store.removeItem(storageKey);
      return null;
    }
  }

  public key(index: number): string {
    return this._keys[index];
  }

  public getStorageKeyName(key: string): string {
    key = `${STORAGE_KEY}.${key}`;
    return key;
  }

  public on(key: string, callback: (value: any) => void) {
    const fullKey = this.getStorageKeyName(key);

    if (typeof window !== 'undefined') {
      const nativeListener = (event: StorageEvent) => {
        if (event.key === fullKey) {
          let value = event.newValue || null;
          try {
            if (value !== null) {
              value = JSON.parse(value);
            }
          } catch (error) {
            // nevermind
          }
          callback(value);
        }
      };

      const listener = () => {
        callback(this.getItem(key));
      };

      window.addEventListener('storage', nativeListener);
      this.emitter.addListener(key, listener);

      return () => {
        window.removeEventListener('storage', nativeListener);
        this.emitter.removeListener(key, listener);
      };
    }

    return () => {};
  }

  private get _keys(): string[] {
    const keysArray: string[] = [];
    for (let i = this._store.length - 1; i >= 0; --i) {
      const key = this._store.key(i);
      if (key?.search(STORAGE_KEY) === 0) {
        keysArray.push(key);
      }
    }
    return keysArray;
  }

  public getTokens() {
    const value = this.getItem<AuthenticationServiceTokens | TokensResponse>(
      tokenKey
    );

    if (value && 'refresh_token' in value) {
      // Old tokens format, attempt to convert to the new one
      try {
        return convertCognitoTokens(value);
      } catch {
        return null;
      }
    }

    return value;
  }

  public clearTokens() {
    this.removeItem(tokenKey);
  }

  public setTokens(tokens: AuthenticationServiceTokens) {
    this.setItem(tokenKey, tokens);
    return this.getTokens();
  }
}

const emptyStorage = {
  getTokens: () => null,
  clearTokens: () => {},
  setTokens: () => null,
  clear: () => {},
  getItem: () => null,
  key: () => {
    throw Error('not implemented');
  },
  removeItem: () => {},
  setItem: () => {},
  on: () => {},
};

export const secfiStorage =
  typeof window !== 'undefined' ? new SecfiStorage() : emptyStorage;
