import { Metric, onCLS, onFID, onLCP, onTTFB } from 'web-vitals';

import { analytics } from './analyticsService';

const performanceMetricEventName = 'Metrics Measured';

enum PerformanceMetric {
  WebVitalsCLS = 'cls',
  WebVitalsFID = 'fid',
  WebVitalsLCP = 'lcp',
  WebVitalsTTFB = 'ttfb',
  CustomPreReactTime = 'pre-react-time',
  CustomRouteLoadTime = 'route-load-time',
}

const getPathname = (): string | undefined => {
  try {
    return window.location.pathname;
  } catch {
    return undefined;
  }
};

const getTimeSinceNavigationStart = () => {
  try {
    return performance.now();
  } catch {
    return -1;
  }
};

const createMetricReporter = (name: PerformanceMetric) => (metric: Metric) => {
  analytics.track(performanceMetricEventName, {
    name,
    pathname: getPathname(),
    value: metric.value,
  });
};

export const setUpWebVitalsMetricsCollection = () => {
  if (typeof window === 'undefined') {
    return;
  }

  // if the web vitals tracking has already been configured,
  // don't do it for the second time.
  if (window.__SECFI_WEB_VITALS_TRACKED) {
    return;
  }

  window.__SECFI_WEB_VITALS_TRACKED = true;

  onCLS(createMetricReporter(PerformanceMetric.WebVitalsCLS));
  onFID(createMetricReporter(PerformanceMetric.WebVitalsFID));
  onLCP(createMetricReporter(PerformanceMetric.WebVitalsLCP));
  onTTFB(createMetricReporter(PerformanceMetric.WebVitalsTTFB));
};

export const trackPreReactTime = () => {
  analytics.track(performanceMetricEventName, {
    name: PerformanceMetric.CustomPreReactTime,
    pathname: getPathname(),
    value: getTimeSinceNavigationStart(),
  });
};

const getGuardKey = (pathname: string): string => {
  return `route-load-time.guard[${pathname}]`;
};

const initialPathnameKey = 'route-load-time.initial-pathname';

export const initializeRouteLoadTimeTracking = () => {
  const pathname = getPathname();
  if (!pathname) {
    return;
  }

  try {
    sessionStorage.setItem(initialPathnameKey, pathname);
  } catch {
    // pass
  }
};

/**
 * Tracks the time it takes for a route to load.
 *
 * Together with `initializeRouteLoadTimeTracking`, this function
 * will only report the route load time once per session and only
 * for the "initial" navigation.
 */
export const trackRouteLoadTime = () => {
  const currentPathname = getPathname();
  const initialPathname = sessionStorage.getItem(initialPathnameKey);

  if (!initialPathname || !currentPathname) {
    return;
  }

  // the purpose of this check is to make sure the reporting only
  // happens if the user is on the same route they initially navigated to.
  if (
    initialPathname !== currentPathname &&
    // additional handling for the case when the user
    // lands on a page without a trailing slash and
    // the redirect is triggered
    initialPathname + '/' !== currentPathname
  ) {
    return;
  }

  const sessionKey = getGuardKey(currentPathname);

  const isReportedInCurrentSession = Boolean(
    sessionStorage.getItem(sessionKey)
  );

  // do not report the metric if it has already been reported
  if (isReportedInCurrentSession) {
    return;
  }

  // the value doesn't matter, could be anything
  sessionStorage.setItem(sessionKey, 'true');

  analytics.track(performanceMetricEventName, {
    name: PerformanceMetric.CustomRouteLoadTime,
    pathname: getPathname(),
    value: getTimeSinceNavigationStart(),
  });
};
